<template>
  <v-form
    ref="form"
    data-cy="request-quotation-form"
    @submit.prevent="onSubmit()"
  >
    <v-row
      align="center"
      no-gutters
    >
      <v-col
        cols="12"
        class="text-center mt-2 mb-4"
      >
        <div v-t="'tips.productDetails.thankYouForYourInterest'" />
        <div
          v-t="
            'tips.productDetails.hereYouCanBeMoreSpecificAboutYourRequestAndLeaveYourContactInformation'
          "
        />
      </v-col>
      <v-col
        cols="12"
        data-cy="service-subject-select"
      >
        <v-select
          v-model="formData.subject"
          :label="labels.subject"
          :error="Boolean(errors.subject)"
          :messages="errors.subject"
          :items="availableSubjects"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="formData.text"
          :label="labels.request"
          :rules="[v => !!v || $t('errors.fieldIsRequired')]"
          data-cy="service-specify-request"
        />
      </v-col>
    </v-row>
    <v-radio-group
      v-model="formData.preferred"
      :rules="[v => !!v || $t('errors.pleaseSelectPreferredContactMethod')]"
    >
      <v-row
        no-gutters
        align="baseline"
      >
        <v-col cols="7">
          <v-text-field
            v-model="formData.phone"
            :label="labels.phone"
            :error="Boolean(errors.phone)"
            :messages="errors.phone"
            data-cy="service-phone"
          />
        </v-col>
        <v-col cols="5">
          <v-radio
            :label="$t('tips.productDetails.preferredContactMethod')"
            value="phone"
            class="ml-4"
            data-cy="service-email-phone"
          />
        </v-col>
        <v-col cols="7">
          <v-text-field
            v-model="formData.email"
            :label="labels.email"
            :error="Boolean(errors.email)"
            :messages="errors.email"
            data-cy="service-email"
          />
        </v-col>
        <v-col cols="5">
          <v-radio
            :label="$t('tips.productDetails.preferredContactMethod')"
            value="email"
            class="ml-4"
            data-cy="service-email-radio"
          />
        </v-col>
      </v-row>
      <template #message="{ message }">
        <v-row dense>
          <v-col
            offset="7"
            cols="5"
          >
            <span class="ml-4">
              {{ message }}
            </span>
          </v-col>
        </v-row>
      </template>
    </v-radio-group>
    <v-row
      no-gutters
      align="center"
    >
      <v-col
        cols="12"
        data-cy="time-zone"
      >
        <core-element-time-zone-selector
          :time-zone="formData.timeZone"
          :set-time-zone="setTimeZone"
        />
      </v-col>
    </v-row>

    <core-element-errors-message
      :show-errors="showApiErrors"
      :errors="errors"
    />
    <div class="mt-5 text-center">
      <v-btn
        rounded
        depressed
        color="primary"
        data-cy="service-submit"
        type="submit"
        :loading="loading"
      >
        {{ $t('tips.productDetails.notifyMyAccountManager') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { quotationRequestSubjects } from '@/constants/quotationRequestSubjects'
import { REQUEST_TYPE_QUOTATION } from '@/constants/app'

export default {
  props: {
    defaultSubject: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formData: {},
      labels: {
        subject: this.$i18n.t('tips.productDetails.subject'),
        request: this.$i18n.t('tips.productDetails.specifyRequest'),
        phone: this.$i18n.t('tips.productDetails.phone'),
        timeZone: this.$i18n.t('tips.productDetails.timeZone'),
        email: this.$i18n.t('tips.productDetails.eMail')
      }
    }
  },
  computed: {
    ...mapState('support', ['loading', 'errors', 'showApiErrors']),
    ...mapGetters('client', ['getBillingDetailDataById', 'client']),
    availableSubjects () {
      // eslint-disable-next-line vue-i18n/no-dynamic-keys
      return quotationRequestSubjects.map(i => this.$t(i))
    },
    billingDetails () {
      return this.getBillingDetailDataById(this.$route.params.clientId)
    }
  },
  watch: {
    loading (val, oldVal) {
      if (!val && oldVal !== val && !this.showApiErrors) {
        this.formData = this.getDefaultFormData()
      }
    }
  },
  created () {
    this.resetFormData()
  },
  methods: {
    ...mapActions('support', ['createRequest']),
    setTimeZone (zone) {
      this.formData = { ...this.formData, timeZone: zone }
    },
    onSubmit () {
      if (this.$refs.form.validate()) {
        this.createRequest(this.formData)
      }
    },
    getDefaultFormData (availableSubjects = this.availableSubjects) {
      return {
        type: REQUEST_TYPE_QUOTATION,
        subject: this.$t(this.defaultSubject),
        clientId: this.$route.params.clientId,
        phone: this.billingDetails.phone,
        email: this.client.email
      }
    },
    resetFormData () {
      this.formData = this.getDefaultFormData()
    }
  }
}
</script>
